import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Container from "../components/container";
import FunctionBlock from "../components/functionBlock";
import styles from "./funkcje-crm.module.css";
import Hero from "../components/HeroBanner";

export const query = graphql`
  query {
    heroBg: file(relativePath: { eq: "system-dla-agencji-reklamowej.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie1: file(relativePath: { eq: "kanban.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kanban: file(relativePath: { eq: "kanban.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kalendarz: file(relativePath: { eq: "kalendarz.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kontrahenci: file(relativePath: { eq: "kartakontrahenta-atrybuty.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    szansesprzedazy: file(relativePath: { eq: "szansesprzedazy.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    komunikator: file(relativePath: { eq: "komunikator.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    raporty: file(relativePath: { eq: "lejek-sprzedazy-wartosciowo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    produkty: file(relativePath: { eq: "listaproduktow.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    poczta: file(relativePath: { eq: "skrzynka-pocztowa.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    fakturowanie: file(relativePath: { eq: "fakturowanie.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ofertowanie: file(relativePath: { eq: "ofertowanie.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    panel: file(relativePath: { eq: "pulpitkontrahenta.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Funkcje = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Funkcje systemu Empireo"
        description="Funkcje systemu dla agencji reklamowej i marketingowej ✅ Jak zarządzać szansami sprzedaży? • Jak skutecznie planować zlecenia? • Zapytaj o bezpłatne demo!"
        keywords={["funkcje systemu dla agencji reklamowej"]}
      />
      <Hero heroImage={data.heroBg} justifyContent="center">
        <h1>Poznaj funkcje systemu Empireo</h1>
        <h2>Zarządzaj pracą agencji reklamowej w jednym miejscu</h2>
      </Hero>
      <Container maxWidth="1400px">
        <div className={styles.funkcjeContainer}>
          <FunctionBlock
            title="Zarządzanie projektami i zleceniami"
            img={data.kanban.childImageSharp.fluid}
            learnMore="/zarzadzanie-projektami/"
          >
            Zarządzaj zleceniami i projektami swojej agencji reklamowej w
            systemie Empireo. Pracuj z widokiem Kanban, organizuj zespół z
            kalendarzem zleceń i analizuj wyniki z automatycznymi raportami.
          </FunctionBlock>

          <FunctionBlock
            title="Koordynacja pracy zespołu"
            img={data.kalendarz.childImageSharp.fluid}
            learnMore="/koordynacja-pracy/"
          >
            Efektywnie organizuj pracę swojego zespołu dzięki zestawowi
            wbudowanych w Empireo narzędzi. Planuj zadania, analizuj zdarzenia i
            koordynuj działania pracowników na współdzielonym kalendarzu.
          </FunctionBlock>

          <FunctionBlock
            title="Baza kontrahentów"
            img={data.kontrahenci.childImageSharp.fluid}
            learnMore="/baza-wiedzy-o-kontrahentach/"
          >
            Historia kontaktów z Twoimi klientami to kopalnia bezcennej wiedzy.
            Sprawdź, w jaki sposób możesz czerpać z niej korzyści i jak
            dopasować ją do wymagań Twojej agencji reklamowej.
          </FunctionBlock>
          <FunctionBlock
            title="Zarządzanie szansami sprzedaży"
            img={data.szansesprzedazy.childImageSharp.fluid}
            learnMore="/zarzadzanie-szansami-sprzedazy/"
          >
            Twórz wraz z Empireo nowe kampanie sprzedażowe i marketingowe bez
            żadnych ograniczeń. Definiuj ich poszczególne etapy, wizualizuj w
            widoku Kanban, analizuj za pomocą raportów i optymalizuj.
          </FunctionBlock>

          <FunctionBlock
            title="Sprawna komunikacja w zespole"
            img={data.komunikator.childImageSharp.fluid}
            learnMore="/komunikator/"
          >
            Zadbaj o swobodny przepływ informacji w firmie dzięki komunikacyjnym
            funkcjonalnościom systemu Empireo. Elastyczny komunikator,
            edytowalne i ogłoszenia są do Twojej dyspozycji.
          </FunctionBlock>

          <FunctionBlock
            title="Automatyczne raportowanie"
            img={data.raporty.childImageSharp.fluid}
            learnMore="/raporty/"
          >
            <br />
            Empireo oferuje Ci zestaw automatycznych raportów, dzięki którym
            będziesz mógł monitorować aktywność swojej agencji oraz
            optymalizować jej procesy.
            <br />
            <br />
            <br />
          </FunctionBlock>

          <FunctionBlock
            title="Baza produktów i usług"
            img={data.produkty.childImageSharp.fluid}
            learnMore="/baza-produktow/"
          >
            Empireo umożliwia budowę współdzielonej, szczegółowej bazy wiedzy o
            produktach oraz usługach. Twórz własne kategorie produktów, generuj
            dokumenty handlowe i ustawiaj indywidualne cenniki oraz rabaty.
          </FunctionBlock>

          <FunctionBlock
            title="Skrzynka pocztowa (IMAP)"
            img={data.poczta.childImageSharp.fluid}
            learnMore="/integracja-z-poczta/"
          >
            Wysyłaj wiadomości e-mail bezpośrednio z poziomu systemu Empireo.
            Powiąż je z kontrahentem, użyj ich do tworzenia zadań lub zdarzeń.
            Edytuj i dopasowuj nieograniczoną liczbę szablonów.
          </FunctionBlock>

          <FunctionBlock
            title="Fakturowanie lub integracja z ERP"
            img={data.fakturowanie.childImageSharp.fluid}
            learnMore="/faktury-i-platnosci/"
          >
            Wystawiaj faktury bezpośrednio w systemie Empireo lub pobieraj je ze
            swojego oprogramowania handlowo-magazynowego. Dopasuj ich wygląd i
            wyświetl nieopłacone dokumenty.
          </FunctionBlock>

          <FunctionBlock
            title="Ofertowanie i zamówienia"
            img={data.ofertowanie.childImageSharp.fluid}
            learnMore="/ofertowanie-i-zamowienia/"
          >
            Zorganizuj z Empireo proces ofertowania i zbierania zamówień.
            Dostosuj szablony, powiąż dokumenty z kontrahentami i ustaw
            powiadomienia o potrzebie kontaktu.
          </FunctionBlock>

          <FunctionBlock
            title="Panel klienta"
            img={data.panel.childImageSharp.fluid}
            learnMore="/panel-klienta/"
          >
            Zaoferuj swoim klientom możliwość samoobsługi w dedykowanym im
            panelu. Będą mogli przesyłać w nim nowe zlecenia i sprawdzać status
            bieżących.
          </FunctionBlock>
        </div>
      </Container>
    </Layout>
  );
};

export default Funkcje;
