import React from "react";
import Img from "gatsby-image";
import styles from "./functionBlock.module.css";
import { Link } from "gatsby";

const FunctionBlock = ({
  title,
  children,
  img,
  imgAlt,
  imgMaxWidth,
  learnMore
}) => {
  return (
    <div className={styles.functionBlock}>
      <div className={styles.functionBlockWrapper}>
        <h3>{title}</h3>
        <div
          style={{
            width: imgMaxWidth,
            margin: "0 auto"
          }}
        >
          {img ? <Img fluid={img} alt={imgAlt} /> : null}
        </div>
        <p>{children}</p>
        <div className={styles.learnMore}>
          <Link to={learnMore}>Dowiedz się więcej</Link>
        </div>
      </div>
    </div>
  );
};

export default FunctionBlock;
